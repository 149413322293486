<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>Contact</h2>
			<b><h3> Freight House Inc</h3></b>

				<a href="tel:(872)250-3400">(872)250-3400</a><br>
				<a href="mailto:info@freighthousecorp.com">info@freighthousecorp.com</a><br>
        <p>720 Plainfield Rd Ste 105B,
        Willowbrook, IL, 60527</p>

		</article>
	</div>
</section>

<section class="pt-4 pb-0">
	<div class="container">
		<article>
      <h2>SHIPPING SOLUTIONS</h2>
      <b>TRUCKLOAD SERVICE</b>
			<p>Freight House Inc. has you covered across all 48' States. Our primary focus for truckload coverage is Midwest, South, Southeast, and East coast.</p>
      <p>We love truckload and are good at it.</p>
      <p>Dedicated lanes or spot bid, we are able to assist you with your daily demand.</p>
      <p>Constant communication throughout transit, creating visibility for you and your team to keep your customers up to date with transit.</p>
      <p>Have a pinch point in an area outside of our coverage? No problem. We can place dedicated capacity based on your needs.</p>

      <b>HIGH VALUE TRANSPORT</b>

      <p>We have become experts over the past 9 years with high-value cargo, handling this service for some of the largest manufacturers in the USA. Our personnel is formally trained with operating equipment for secured transit. Team and solo service available and based on your needs.​</p>
      <p style="text-transform: uppercase;">Our high-value cargo transport includes:</p>
      <ul>
        <li>Cargo coverage starts at $250,000.00</li>
        <li>Safe and reliable transport</li>
        <li>On time pick up & on time delivery</li>
        <li>Dropped trailers provided</li>
        <li>Secured Storage/Secured Yard</li>
      </ul>

      <b>EXPEDITED TIME CRITICAL</b>
      <p>Freight House Inc. offers a cost and service effective alternative for the reliable expedited service of your freight.</p>
      <p>Our extensive operational experience has allowed us to create value for our customers by utilizing a wide range of equipment and freight shipping services.</p>
      <p>Freight House Inc. utilizes straight trucks, semi-tractor trailers, and team drivers to make sure your emergency expedited freight is delivered when promised.</p>
      <p>We provide dock-to-dock, exclusive expedited shipping, and overnight shipping services for rush-critical freight servicing the Midwest, South, Southeast, and East Coast.</p>


      <b>DEDICATED SOLUTIONS</b>

      <p>Dedicated truck service is when a truck and a driver is assigned to your company. These units and drivers are tailored to meet your or your client’s specific needs. Having dedicated equipment solves any issues of drivers being tied to another pick up or delivery, we guarantee our equipment will arrive at the agreed time to keep you and your customers on schedule.</p>
      <p>Our dedicated trucking service was designed to offer you a turn-key solution to improve your shipping efficiency. It's like owning a truck without the late deliveries, liability, the expense of maintenance and complex logistics problems.​</p>
      <p>Rent equipment/drivers for the day, week, month or continuously. We are here to create cost cutting solutions for you.</p>


    </article>
	</div>
</section>
